import React from "react";
import { ThemeProvider as OThemeProvider } from "styled-components";

const sizes = {
  phone: 768,
  tablet: 1024,
  desktop: 1440,
};

const baseTheme = {
  colors: {
    primary: "#3498db",
    primaryDark: "#2980b9",
    primaryLight: "#d6eaf8",
    blue: "#3498db",
    blueDark: "#2980b9",
    blueLight: "#d6eaf8",
    green: "#2ecc71",
    greenDark: "#27ae60",
    greenTransparent: "rgba(46, 204, 113, 0)",
    red: "#e74c3c",
    redDark: "#c0392b",
    yellow: "#f1c40f",
    white: "#fff",
    gray: "#212529",
    clouds: "#f5f7fb",
    creme: "#f1f4f3",
    cremeDark: "#d9dcdb",
    grayTransparent: "rgba(33, 37, 41, 0.8)",
    whiteTransparent: "rgba(255, 255, 255, 0.8)",
  },
  queries: {
    tiny: `@media (max-width: ${sizes.phone}px)`,
    phone: `@media (min-width: ${sizes.phone}px)`,
    tablet: `@media (min-width: ${sizes.tablet}px)`,
    desktop: `@media (min-width: ${sizes.desktop}px)`,
  },
};

const ThemeProvider = ({ children, theme = baseTheme }) => (
  <OThemeProvider theme={theme}>{children}</OThemeProvider>
);

export default ThemeProvider;
