import React, { useState } from "react";
import { graphql, useStaticQuery } from "gatsby";
import styled from "styled-components";
import { MdClose } from "react-icons/md";

import { queries } from "../shared/layout";

const Announcement = ({ children }) => {
  const data = useStaticQuery(popupQuery);
  const {
    allAirtable: { nodes: popupInfo },
  } = data;
  const {
    data: { name, content, isVisible },
  } = popupInfo[0];

  const [isPopupVisible, setIsPopupVisible] = useState(true);

  return (
    <>
      {children}
      <Wrapper
        className={`${!(isPopupVisible && isVisible === "true") && "hidden"}`}
      >
        <button onClick={() => setIsPopupVisible(false)}>
          <MdClose />
        </button>

        <div className="content">
          <h4>{name}</h4>
          <p>{content}</p>
        </div>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.div`
  width: 90%;
  text-align: center;
  background-color: ${({ theme }) => theme.colors.primaryLight};
  position: fixed;
  bottom: 5%;
  left: 50%;
  transform: translate(-50%, 0%);
  z-index: 9999;

  border: 1rem solid ${({ theme }) => theme.colors.primaryDark};
  border-radius: 1rem;
  display: grid;
  grid-template-rows: min-content max-content;
  padding: 0.5rem;

  &.hidden {
    display: none;
  }

  button {
    width: 3rem;
    height: 3rem;
    justify-self: end;
    background-color: transparent;
    border: none;

    display: flex;
    justify-content: center;
    align-items: center;

    svg {
      width: 100%;
      height: 100%;
      color: ${({ theme }) => theme.colors.primaryDark};
    }
  }

  .content {
    padding: 3rem 2rem;
    padding-top: 0;
  }

  ${queries.tablet} {
    width: 50%;
  }
`;

const popupQuery = graphql`
  query {
    allAirtable(filter: { table: { eq: "PopupInfo" } }) {
      nodes {
        id
        data {
          name
          content
          isVisible
        }
      }
    }
  }
`;

export default Announcement;
