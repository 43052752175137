import React from "react";
import ThemeProvider from "./src/components/ThemeProvider";
import { createGlobalStyle } from "styled-components";
import { queries } from "./src/shared/layout";
import Announcement from "./src/components/Announcement";

const GlobalStyle = createGlobalStyle`
*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html {
  font-size: 62.5%;
}
body {
  font-family: "Source Sans Pro", "Roboto", sans-serif;
  background: #f5f7fb;
  color: #212529;
  line-height: 1.5; 
}
ul {
  list-style-type: none;
}
a {
  text-decoration: none;
  color: inherit;
  cursor: pointer;
}
img {
  width: 100%;
  display: block;
}

p {
 font-size: 1.6rem;
}

li, a {
font-size: 1.8rem
}

h1, h2, h3, h4, h5, h6 {
  font-family: "Signika";
}

h1 {
 font-size: 6rem;
 line-height: 6rem;
}

h2 {
 font-size: 2.8rem; 
}

h3 {
 font-size: 2.4rem;
}

h4 {
  font-size: 2rem;
  font-weight: 500;
}

h5 {
  font-size: 1.8rem;
  font-weight: 500;
}

${queries.tablet} {
  h1 {
    font-size: 12rem;
    line-height: 10rem;
  }

  h2 {
    font-size: 3.2rem; 
  }

  h3 {
    font-size: 4rem;
  }

  h4 {
    font-size: 2.2rem;
  }

  h5 {
    font-size: 1.8rem;
  }
}

button {
  font-family: inherit;
  cursor: pointer;
}

.icon-draw {
  display: none;

  ${queries.tablet} {
    display: block;
  }
}

.section {
  padding: 4rem 2rem;
  width: 100%;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center; 

  .content {
    height: 100%;
    width: 100%;
    max-width: 120rem;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  ${queries.tablet} {
    padding: 6rem;
  }
}
`;

export const onRenderBody = ({ setHtmlAttributes }) => {
  setHtmlAttributes({ lang: "pl" });
};

export const wrapPageElement = ({ element }) => {
  return (
    <>
      <GlobalStyle />
      <ThemeProvider>
        <Announcement>{element}</Announcement>
      </ThemeProvider>
    </>
  );
};
